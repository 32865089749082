import React from 'react';
import Moment from 'moment';
import Resizer from "react-image-file-resizer"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";

import './App.css';
import './Button.css';
import './DateDefault.css';
import 'swiper/css';
import "swiper/css/navigation";

import phone from "./phone.svg";
import gear from "./gear.svg";

function App() {

    const useLocalStorage = (storageKey, fallbackState) => {
        const [value, setValue] = React.useState(
            JSON.parse(localStorage.getItem(storageKey)) ?? fallbackState
        );

        React.useEffect(() => {
            localStorage.setItem(storageKey, JSON.stringify(value));
        }, [value, storageKey]);

        return [value, setValue];
    };

    const [sentence1, setSentence1] = useLocalStorage('sentence1', '');

    const onChangeSentence1 = (e) => {

        setSentence1(e.target.value);
    };

    const [sentence2, setSentence2] = useLocalStorage('sentence2', '');

    const onChangeSentence2 = (e) => {

        setSentence2(e.target.value);
    };

    const [sentence3, setSentence3] = useLocalStorage('sentence3', '');

    const onChangeSentence3 = (e) => {

        setSentence3(e.target.value);
    };

    const today = Moment();
    const [date, setDate] = useLocalStorage('date', today.format('YYYY-MM-DD'));
    const initialDays = today.diff(date, 'days');

    const onChangeDate = (e) => {
        setDate(e.target.value);
    };

    const [phoneNumber, setPhoneNumber] = useLocalStorage('phoneNumber', '');

    const onChangePhoneNumber = (e) => {
        setPhoneNumber(e.target.value);
    };

    const [isOpen, setOpen] = useLocalStorage('is-open', false);
    const hiddenClass = !isOpen ? 'is-hidden' : '';

    const handleToggle = () => {
        setOpen(!isOpen);
    }

    const fileInput = React.createRef();
    const deleteButton = React.createRef();

    const [image, setImage] = useLocalStorage('image', false);
    const [imageName, setImageName] = useLocalStorage('image-name', '');
    const [isHidden, setHidden] = useLocalStorage('is-hidden', true);
    const buttonHiddenClass = isHidden && !image  ? 'is-hidden' : '';

    const onRemoveImage = () => {

        fileInput.current.value = null;

        setHidden(isHidden);
        setImage(false);
        setImageName('');
    }

    const onChangeImage = (event) => {
        let fileInput = false;
        if (event.target.files[0]) {
            fileInput = true;
        }

        if (fileInput) {

            setImageName(event.target.files[0].name);

            try {
                Resizer.imageFileResizer(
                    event.target.files[0],
                    300,
                    300,
                    "JPEG",
                    80,
                    0,
                    (uri) => {
                        setImage(uri);
                    },
                    "base64",
                    200,
                    200
                );


            } catch (err) {
                console.log(err);
            }
        }
    }

    return (
    <div className="App">
        <header className="App-header">
            <a
                className="Button"
                href={`tel:${phoneNumber}`}>
                <img src={phone} alt="Phone call"/>
            </a>
            <button
                className="Button"
                onClick={handleToggle}>
                <img src={gear} alt="Edit"/>
            </button>
        </header>
        <div className="main">
            <h1
                className="DateDefault-result">
                <span>
                    {initialDays}
                </span>
                <span>Tage clean</span>
            </h1>
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                navigation={true}
                modules={[Navigation]}
            >
                {sentence1 &&
                    <SwiperSlide>
                        <h4>{sentence1}</h4>
                    </SwiperSlide>
                }
                {sentence2 &&
                    <SwiperSlide>
                        <h4>{sentence2}</h4>
                    </SwiperSlide>
                }
                {sentence3 &&
                    <SwiperSlide>
                        <h4>{sentence3}</h4>
                    </SwiperSlide>
                }
                {image &&
                    <SwiperSlide>
                        <img src={image} alt="Your image to motivate you" />
                    </SwiperSlide>
                }
            </Swiper>
        </div>
        <div className={`edit-overlay ${hiddenClass}`}>
            <button
                className="Button Button--close"
                onClick={handleToggle}>X</button>
            <div className="edit-overlay__wrapper">
                <div className="edit-overlay__input-wrapper">
                    <label htmlFor="date">Startdatum wählen</label>
                    <input type="date"
                           id="date"
                           value={date}
                           max={today}
                           onChange={onChangeDate} />
                </div>
                <div className="edit-overlay__input-wrapper">
                    <label htmlFor="phoneNumber">Notfallkontakt eintragen</label>
                    <input type="tel"
                           id="phoneNumber"
                           value={phoneNumber}
                           onChange={onChangePhoneNumber} />
                </div>
                <div className="edit-overlay__input-wrapper">
                    <label htmlFor="sentence">Motivationssatz 1</label>
                    <input
                        type="text"
                        id="sentence1"
                        value={sentence1}
                        onChange={onChangeSentence1} />
                </div>
                <div className="edit-overlay__input-wrapper">
                    <label htmlFor="sentence2">Motivationssatz 2</label>
                    <input
                        type="text"
                        id="sentence2"
                        value={sentence2}
                        onChange={onChangeSentence2} />
                </div>
                <div className="edit-overlay__input-wrapper">
                    <label htmlFor="sentence3">Motivationssatz 3</label>
                    <input
                        type="text"
                        id="sentence3"
                        value={sentence3}
                        onChange={onChangeSentence3} />
                </div>
                <div className="edit-overlay__input-wrapper">
                    <label htmlFor="image">Bild hochladen (max. 2MB)</label>
                    <div>
                        <input type="file"
                               id="image"
                               onChange={onChangeImage}
                               ref={fileInput}/>
                        <span>{imageName}</span>
                    </div>
                    <div className="edit-overlay__image-wrapper">
                        <button
                            className={`Button Button--delete ${buttonHiddenClass}`}
                            onClick={onRemoveImage}
                            ref={deleteButton}>Entfernen X</button>
                        {image &&
                            <img src={image} alt={imageName} />
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default App;
